
import React, { useEffect, useState } from "react";

import axios from "axios";

import Rezago from '../../API/MICROSERVICES/rezago/rezago';
import Tracking from "../../API/MICROSERVICES/tracking/tracking";
import UbicFisica from "../../API/MICROSERVICES/ubicacacionFisica/ubicacionFisica";



export default function Pruebas(props: any) {


  useEffect(() => {
    algo();

  }, []);


  async function algo() {

    console.log("Ejecutando algo");


    let UF = {

      encacodigobarra: "92002441001733959269219001",
      eprocodigo: 1,
      fechacompromiso: "2024-10-05",

    }


    await UbicFisica.ObtenerUbicacionFisica(UF).then((resp)=>{

      console.log("Resp", resp);

    })

    








  }



  return (
    <>
      <h1>Hola Mundo</h1>
      <h1>Chao Mundo</h1>

    </>
  )

}