let poderAncestral = window.location.ancestorOrigins[0];

let autorizar: boolean = false;

if (window.location.host.toLocaleLowerCase().includes("localhost")) {
  autorizar = true;
} else if (poderAncestral !== undefined) {
  if (window.location.ancestorOrigins[0].toLocaleLowerCase().includes("starken.topdesk.net")) {
    autorizar = true;
  }
}

var referrer = document.referrer;
console.log("referrer url", referrer);

export const inicio = {
  permitido: autorizar,
}

let TOPDESK: string = "" + process.env.REACT_APP_TOPDESK;
let TOPDESK_SUB_CATEGORY: string = "" + process.env.REACT_APP_SUB_CATEGORY_ID;
let TOPDESK_PAGE_SIZE: string = "" + process.env.REACT_APP_PAGE_SIZE;
let TRACKING_DETALLE: string = "" + process.env.REACT_APP_TRACKING;
let REZAGO_DATOS: string = "" + process.env.REACT_APP_REZAGO;
let TDK_MESES_VALIDACION: string = "" + process.env.REACT_APP_MESES_VALIDACION;
let UBICACION_FISICA: string = "" + process.env.REACT_APP_UBICACION_FISICA;

// ESTADOS
const EXTRAVIADO: number = Number(process.env.REACT_APP_EXTRAVIADO);
const PENDIENTE_DESPACHO: number = Number(process.env.REACT_APP_PENDIENTE_DESPACHO);
const NOMINADO: number = Number(process.env.REACT_APP_NOMINADO);
const EN_TRANSITO: number = Number(process.env.REACT_APP_EN_TRANSITO);
const PENDIENTE_ENTREGA: number = Number(process.env.REACT_APP_PENDIENTE_ENTREGA);
const EN_REPARTO: number = Number(process.env.REACT_APP_EN_REPARTO);
const PENDIENTE_ENTREGA_EX: number = Number(process.env.REACT_APP_PENDIENTE_ENTREGA_EX);
const ANULADO: number = Number(process.env.REACT_APP_ANULADO);
const CERRADO_EXCEPCION: number = Number(process.env.REACT_APP_CERRADO_EXCEPCION);
const DECOMISADO: number = Number(process.env.REACT_APP_DECOMISADO);
const DEVUELTO: number = Number(process.env.REACT_APP_DEVUELTO);
const ENTREGADO: number = Number(process.env.REACT_APP_ENTREGADO);
const REDESTINADO: number = Number(process.env.REACT_APP_REDESTINADO);
const REMATADO: number = Number(process.env.REACT_APP_REMATADO);
const REZAGO_PARCIAL: number = Number(process.env.REACT_APP_REZAGO_PARCIAL);


// URL ENVIO FORMULARIO

const URL_ENVIO_FOMULARIO_BASE: string = "" + (process.env.REACT_APP_URL_BASE_FORMULARIO_ENVIO);

export const URL_ENVIO_FOMULARIO = {

  URL_ENVIO_FOMULARIO: URL_ENVIO_FOMULARIO_BASE

}


// DIF TICKETS PROCESADOS

const TIPO_TK_01_REDESTINAR: string = "" + (process.env.REACT_APP_TIPO_TK_01);
const TIPO_TK_02_DEVOLVER: string = "" + (process.env.REACT_APP_TIPO_TK_02);
const TIPO_TK_03_SACAR_A_REPARTO: string = "" + (process.env.REACT_APP_TIPO_TK_03);
const TIPO_TK_04_CAMBIAR_TIPO_ENTREGA: string = "" + (process.env.REACT_APP_TIPO_TK_04);
const TIPO_TK_05_DESPACHAR_SUC_DESTINO: string = "" + (process.env.REACT_APP_TIPO_TK_05);


export const TIPO_TK_PROCESADO = {
  TIPO_TK_01_REDESTINAR: TIPO_TK_01_REDESTINAR,
  TIPO_TK_02_DEVOLVER: TIPO_TK_02_DEVOLVER,
  TIPO_TK_03_SACAR_A_REPARTO: TIPO_TK_03_SACAR_A_REPARTO,
  TIPO_TK_04_CAMBIAR_TIPO_ENTREGA: TIPO_TK_04_CAMBIAR_TIPO_ENTREGA,
  TIPO_TK_05_DESPACHAR_SUC_DESTINO: TIPO_TK_05_DESPACHAR_SUC_DESTINO
}


export const ESTADOS = {
  EXTRAVIADO: EXTRAVIADO,
  PENDIENTE_DESPACHO: PENDIENTE_DESPACHO,
  NOMINADO: NOMINADO,
  EN_TRANSITO: EN_TRANSITO,
  PENDIENTE_ENTREGA: PENDIENTE_ENTREGA,
  EN_REPARTO: EN_REPARTO,
  PENDIENTE_ENTREGA_EX: PENDIENTE_ENTREGA_EX,
  ANULADO: ANULADO,
  CERRADO_EXCEPCION: CERRADO_EXCEPCION,
  DECOMISADO: DECOMISADO,
  DEVUELTO: DEVUELTO,
  ENTREGADO: ENTREGADO,
  REDESTINADO: REDESTINADO,
  REMATADO: REMATADO,
  REZAGO_PARCIAL: REZAGO_PARCIAL
}

export const VALIDACIONES = {
  TDK_MESES_VALIDACION: TDK_MESES_VALIDACION
}

export const MICROSERVICIOS = {

  // topdesk
  TOPDESK:                   // base topdesk
    TOPDESK,

  // TRACKING
  TRACKING_DETALLE:                   //detalle
    TRACKING_DETALLE,

  REZAGO:
    REZAGO_DATOS,

  UBICACION_FISICA:
    UBICACION_FISICA

}

export const TOPDESK_CONFIG = {

  SUB_CATEGORY: TOPDESK_SUB_CATEGORY,
  PAGE_SIZE: TOPDESK_PAGE_SIZE

}

// USER UBICACION_FISICA

let UF_USER: string = "" + process.env.REACT_APP_UF_USER;
let UF_PASS: string = "" + process.env.REACT_APP_UF_PASS;

export const AUTENTICACION_UBICACION_FISICA = {
  user: UF_USER,
  pass: UF_PASS
}

// USER CRM

let CRM_USER: string = "" + process.env.REACT_APP_CRM_USER;
let CRM_PASS: string = "" + process.env.REACT_APP_CRM_PASS;

export const AUTENTICACION_CRM = {
  user: CRM_USER,
  pass: CRM_PASS
}


// USER CONSULTA TOPDESK
let CONSULTA_TDK_USER: string = "" + process.env.REACT_APP_CONSULTA_TDK_USER;
let CONSULTA_TDK_PASS: string = "" + process.env.REACT_APP_CONSULTA_TDK_PASS;

export const CONSULTA_TDK = {
  user: CONSULTA_TDK_USER,
  pass: CONSULTA_TDK_PASS
}


// USER TOPDESK AGENCIAS
let TDK_USER_AGENCIAS: string = "" + process.env.REACT_APP_USER_TDK_AGENCIAS;
let TDK_PASS_AGENCIAS: string = "" + process.env.REACT_APP_PASS_TDK_AGENCIAS;

export const AUTENTICATION_TDK_AGENCIAS = {
  user: TDK_USER_AGENCIAS,
  pass: TDK_PASS_AGENCIAS
}


// USER VALIDACION TOPDESK
let TDK_USER_VALIDACION: string = "" + process.env.REACT_APP_USER_TDK_VALIDACION;
let TDK_PASS_VALIDACION: string = "" + process.env.REACT_APP_PASS_TDK_VALIDACION;

export const AUTENTICATION_TDK_VALIDACION = {
  user: TDK_USER_VALIDACION,
  pass: TDK_PASS_VALIDACION
}

let TIME_OUT: number = Number(process.env.REACT_APP_REQUEST_TIMEOUT);

export const REQUEST_TIMEOUT = TIME_OUT; // segundos

let DIAS_ANTERIORES: number = Number(process.env.REACT_APP_DIAS_ANTERIORES);

export const TDK_DIAS_ANTERIORES = DIAS_ANTERIORES;


// ESTILOS COLORES
const MAIN_BACKGROUND: string = "" + process.env.REACT_APP_MAIN_BACKGROUND;
const SUCURSALES: string = "" + process.env.REACT_APP_SUCURSALES;
const COLORES: string = "" + process.env.REACT_APP_COLORES;
const TICKETS: string = "" + process.env.REACT_APP_TICKETS;
const FECHAS: string = "" + process.env.REACT_APP_FECHAS;
const PALABRAS_CLAVES: string = "" + process.env.REACT_APP_PALABRAS_CLAVES;
const BACKGROUND_LIMPIEZA: string = "" + process.env.REACT_APP_BACKGROUND_LIMPIEZA;
const LIMPIEZA: string = "" + process.env.REACT_APP_LIMPIEZA;
const BLANCO: string = "" + process.env.REACT_APP_BLANCO;
const VERDE: string = "" + process.env.REACT_APP_VERDE;
const ROJO: string = "" + process.env.REACT_APP_ROJO;
const TICKET_SIN_ESTADO: string = "" + process.env.REACT_APP_TICKET_SIN_ESTADO;
const TICKET_ORIGINAL: string = "" + process.env.REACT_APP_TICKET_ORIGINAL;
const TICKET_REDESTINADO: string = "" + process.env.REACT_APP_TICKET_REDESTINADO;
const TICKET_DEVUELTO: string = "" + process.env.REACT_APP_TICKET_DEVUELTO;
const TICKET_REZAGO: string = "" + process.env.REACT_APP_TICKET_REZAGO;
const ENCABEZADO: string = "" + process.env.REACT_APP_ENCABEZADO;
const FOOTER: string = "" + process.env.REACT_APP_FOOTER;
const GRIS: string = "" + process.env.REACT_APP_GRIS;
const NEGRO: string = "" + process.env.REACT_APP_NEGRO;
const VERDE_AGUA: string = "" + process.env.REACT_APP_VERDE_AGUA;
const AMARILLO_CANARIO: string = "" + process.env.REACT_APP_AMARILLO_CANARIO;

export const ESTILOS_COLORES = {

  MAIN_BACKGROUND: MAIN_BACKGROUND,
  SUCURSALES: SUCURSALES,
  COLORES: COLORES,
  TICKETS: TICKETS,
  FECHAS: FECHAS,
  PALABRAS_CLAVES: PALABRAS_CLAVES,
  BACKGROUND_LIMPIEZA: BACKGROUND_LIMPIEZA,
  LIMPIEZA: LIMPIEZA,
  BLANCO: BLANCO,
  VERDE: VERDE,
  ROJO: ROJO,
  TICKET_SIN_ESTADO: TICKET_SIN_ESTADO,
  TICKET_ORIGINAL: TICKET_ORIGINAL,
  TICKET_REDESTINADO: TICKET_REDESTINADO,
  TICKET_DEVUELTO: TICKET_DEVUELTO,
  TICKET_REZAGO: TICKET_REZAGO,
  ENCABEZADO: ENCABEZADO,
  FOOTER: FOOTER,
  GRIS: GRIS,
  NEGRO: NEGRO,
  VERDE_AGUA: VERDE_AGUA,
  AMARILLO_CANARIO: AMARILLO_CANARIO

}
